import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ProtectedRoute } from "../containers/Auth/protectedRoute";
import "./App.scss";
import Header from "../containers/Header"
import Board from "../containers/Board";
import Login from "../containers/Auth/Login";
import Register from "../containers/Auth/Register";

const App = ()=>{
    return (
      <Router>
        <div className="bg-slate-800 text-white min-h-screen">
          <Header/>
          <Switch>
            <ProtectedRoute exact path="/" component={Board} />
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />
            <Route path="*" component={() => "404 NOT FOUND"} />
          </Switch>
        </div>
      </Router>
    );
  
}

export default App;
