import React, { useState } from "react";
import {useDispatch} from 'react-redux';
import { registerUserAction } from "../../../actions";
import { Link,useHistory } from "react-router-dom";

const Register = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  });
  const [passwordConfirmation,setPasswordConfimation]= useState("")
  const [loading, setLoading] = useState(false);

  const handleFormValidation = (formData) => {
    const {first_name,last_name,email,password} = formData

    const regex =  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!first_name || !last_name) {
      alert("Please fill out entire form.")
      return false
    }
    else if (password !== passwordConfirmation) {
      return alert('Passwords do not match')
    }
    else if(!email || regex.test(email) === false){
        alert("Email not valid.")
        return false;
    }
    return true
    
  }

  return (
    <div className="flex justify-center w-full mt-32">
      <form className="max-w-xs bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" action="POST">

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="first_name">First Name</label>
          <input
          placeholder="First Name"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            name="first_name"
            id="first_name"
            onChange={(e) => {
              setFormData({ ...formData, first_name: e.target.value });
            }}
            />
            </div>

            <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="last_name">Last Name</label>
          <input
          placeholder="Last Name"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            name="last_name"
            id="last_name"
            onChange={(e) => {
              setFormData({ ...formData, last_name: e.target.value });
            }}
            />
            </div>
        
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">Email</label>
          <input
          placeholder="Email"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="email"
            id="email"
            name="email"
            onChange={(e) => {
              setFormData({ ...formData, email: e.target.value });
            }}
            />
            </div>
        
            <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">Password</label>
          <input
          placeholder="Enter your password"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="password"
            name="password"
            id="password"
            onChange={(e) => {
              setFormData({ ...formData, password: e.target.value });
            }}
            />
            </div>
        
            <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password_confirm">Password Confirmation</label>
          <input
          placeholder="Confirm Password"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="password"
            name="password_confirm"
            id="password_confirm"
            onChange={(e) => {
              setPasswordConfimation(e.target.value);
            }}
            />
            </div>

            <div className="flex items-center justify-between">
        <button
        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          disabled={loading}
          onClick={(e) => {
            e.preventDefault();
            if(handleFormValidation(formData)) {
              setLoading(true);
              dispatch(registerUserAction(formData,history))           
            }
          }}
          >
          Submit
        </button>
      <Link className="inline-block align-baseline font-bold text-sm text-green-500 hover:text-green-700" to="/login">Log In</Link>
          </div>
      </form>
    </div>
  );
};

export default Register;
