import React,{ useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { addCardAction,loadUsersAction } from "../../../actions";
import Modal from "react-modal";

Modal.setAppElement("#root");

const AddCard =({...props}) => {
  const dispatch = useDispatch();
  const users = useSelector(state => state.users)
  const [formData,setFormData] = useState({
      titleInput: "",
      priorityInput: 1,
      bodyInput: "",
      createdByInput: null,
      assignedToInput: null,
    })

  const validateForm = (data) => {
    if(!data.createdByInput || !data.assignedToInput ){
      return false;
    }
    return true;
  }

  const handleRequestClose = () =>{
    // resets formData before closing modal
    setFormData({
    titleInput: "",
    priorityInput: 1,
    bodyInput: "",
    createdByInput:null,
    assignedToInput: null
  })

    props.setOpenModal(!props.openModal);
  }
 
    return (
      <Modal 
      style={{
        content:{
      maxWidth: "600px",
      left: "50%",
      transform: "translate(-50%, 0)",
      width: '90%',
      // margin: "auto",
      padding: '.5rem'}}}
       isOpen={props.openModal} 
       onRequestClose={handleRequestClose} 
       onAfterOpen={()=>{dispatch(loadUsersAction())}}
       >
      
      <div className="flex flex-col items-center">
        <button className="bg-green-800 hover:bg-slate-300 hover:text-green-500 font-bold py-1 px-4 text-white focus:outline-none focus:shadow-outline rounded mx-2 my-2" onClick={handleRequestClose}>Close</button>
        <form className="max-w-lg w-full bg-white shadow-md rounded px-4 pt-6 pb-8 mb-4">
          <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
          Title:
          </label>
          <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            onChange={(e)=>{
              setFormData({...formData, titleInput: e.target.value});
            }}
            type="text"
            value={formData.titleInput}
            placeholder="Title"
            />
            </div>
            <div className="mb-4">  
            <label className="block text-gray-700 text-sm font-bold mb-2">
          Task Details:
          </label>
          <textarea
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          name="bodyInput"
          id="bodyInput"
          onChange={(e)=>{
            setFormData({...formData, bodyInput: e.target.value})
          }}
          cols="7"
          placeholder="Task description"
          rows="4"
          ></textarea>
          </div>

          <div className="mb-4">  
       <label className="block text-gray-700 text-sm font-bold mb-2">
          Priority :
          </label>
          <select className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" onChange={(e)=>{
            setFormData({...formData, priorityInput:e.target.value})
          }} name="priorityInput">
                <option value={1}>
                  Low
                </option>
                <option value={2}>
                  Medium
                </option>
                <option value={3}>
                  High
                </option>
                <option value={4}>
                  Blocker
                </option>
          </select>
            </div>
            <div className="mb-4">  
          <label className="block text-gray-700 text-sm font-bold mb-2">
          Created By:
          </label>
        <select className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="created_by" onChange={(e)=>{
          setFormData({...formData,createdByInput:e.target.value})
        }}>
          <option value=''>Select user</option>
          {users.map((user) => {
            return (
              <option key={user.id} value={user.id}>
                {user.first_name}, {user.last_name}
              </option>
            );
          })}
        </select>
          </div>
          <div className="mb-4">  
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Assigned To:
          </label>
        <select className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="assigned_to" onChange={(e)=>{
          setFormData({ ...formData,assignedToInput: e.target.value})
        }}>
          <option value=''>Select user</option>
          {users.map((user) => {
            return (
              <option key={user.id} value={user.id}>
                {user.first_name}, {user.last_name}
              </option>
            );
          })}
        </select>
          </div>
          </form>
        <button className="bg-green-800 hover:bg-slate-300 hover:text-green-500 font-bold py-1 px-4 text-white focus:outline-none focus:shadow-outline rounded mx-2" 
        onClick={(e)=>{
          e.preventDefault();
          if(!validateForm(formData)){
            alert("Please fill out the entire form.")
          }else{
            dispatch(addCardAction(formData))
            handleRequestClose()
          }
        }}>Submit</button>
      </div>
      
  </Modal>
    );
  }


export default AddCard;
