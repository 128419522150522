import React from "react";
import {useSelector} from 'react-redux';
import Card from "../Card";
import {useDispatch} from "react-redux";
import { useDrop } from "react-dnd";
import {editCardAction} from '../../actions'

const Column = ({ ...props})=> {
  const dispatch = useDispatch();
  const {id, name} = props.status
  const cards = useSelector(state => state.cards)
  const filtered = cards.filter(card => card.status_id === id)

  const [{isOver}, drop] = useDrop(() => ({
    accept: "card",
    drop: (item) => dispatch(editCardAction({...item, status_id: id})),
    collect: (monitor)=>({
      isOver: !!monitor.isOver(),
    })
  }))

    return (
      <div className="grid grid-cols-1 auto-rows-min border-2 rounded-md border-slate-800 m-4" ref={drop}>
        <h3 className="text-center font-bold m-4">{name}</h3>
        {filtered.sort((a,b)=>{
          return a.priority_id - b.priority_id
        }).map(card => <Card key={card.id} card={card} />)}
      </div>
    );
  }


export default Column;
