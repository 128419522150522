import React,{useState} from "react";
import {useDispatch} from "react-redux";
import {editCardAction,deleteCardAction} from '../../actions'
import EditCardModal from "./EditCardModal"
import { useDrag } from "react-dnd";

const Card = function ({ ...props }) {

  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const { title, priority, created_by, assigned_to, body} = props.card;

  const [{isDragging},drag] = useDrag(()=>({
    type: "card",
    item: props.card,
    collect: (monitor)=>({
      isDragging: !!monitor.isDragging()
    })
  }))
  
  return (
    <div className="block m-2 cursor-pointer border bg-slate-800/75 border-green-400 rounded-lg hover:shadow-md hover:shadow-green-300/80  hover:text-green-400 transform hover:-translate-y-1 transition-all duration-200" ref={drag}>
      <div className="m-3">
          <h4 className="text-lg mb-2">{title}</h4>
            <span className="text-md text-white my-2">{`Priority: ${priority.name}`}</span>
            <span className="text-md text-white m-2">{`Created by: ${created_by.first_name}, ${created_by.last_name}`}</span>          
        <p className="text-sm text-white my-2">{`Description: ${body}`}</p>
        <p className="text-md text-white my-2">{`Assigned: ${assigned_to.first_name}, ${assigned_to.last_name}`}</p>

        <div className="flex justify-between m-2">
          <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={()=>{setOpenModal(!openModal)}}>Edit</button>

          <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={()=>{
            dispatch(deleteCardAction(props.card))
          }}>Delete</button>

          {props.card.status_id <= 1 ? null : <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={()=>{
            dispatch(editCardAction({...props.card, status_id: props.card.status_id - 1}))
          }}>&#8592;</button> }

         {props.card.status_id >= 3 ? null :  <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={(e)=>{
           dispatch(editCardAction({...props.card, status_id: props.card.status_id + 1}))
          }}>&#8594;</button>}
          
        </div>

        <EditCardModal openModal={openModal} setOpenModal={setOpenModal} card={props.card} />
          </div>
    </div>
  );
};

export default Card;
