export const ADD_CARD = "ADD_CARD";
export const ADD_STATUS = "ADD_STATUS";
export const LOAD_CARD = "LOAD_CARD";
export const LOAD_USER = "LOAD_USER";
export const LOAD_STATUSES = "LOAD_STATUSES";
export const LOAD_PRIORITIES = "LOAD_PRIORITIES";
export const UPDATE_CARD_STATUS = "UPDATE_CARD_STATUS";
export const EDIT_CARD = "EDIT_CARD";
export const DELETE_CARD = "DELETE_CARD";
export const LOGIN_USER = "LOGIN_USER";
export const LOGGED_IN_STATUS = "LOGGED_IN_STATUS";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOADING = "LOADING";


//AUTH
export const loginAction = (email, password,history)  => (dispatch)=>{
  fetch("/api/auth/login",{
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email:email, password:password})
  }).then((response)=>{
    return response.json();
  }).then(data =>{
    
    dispatch({
      type: LOGIN_USER,
      payload: data
    })
    return history.push('/')
  }).catch(err =>{
    console.error(err)
  })
}

export const registerUserAction = (user,history)=> (dispatch) => {
  fetch("/api/auth/register",{
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(user)
  }).then(response =>{
    return response.json();
  }).then(data =>{
    dispatch({
      type: LOGIN_USER,
      payload: data
    })
    return history.push('/')
  }).catch(err =>{
    console.log(err)
    console.error(err)
  })
}

export const loggedInStatusAction = () => (dispatch) => {
  fetch("/api/auth/loggedIn")
  .then(response => {
    dispatch({
      type: LOADING,
      payload: true
    })
     return response.json();
  })
  .then(data => {
   return dispatch({
      type: LOGGED_IN_STATUS,
      payload: data
    })
  })
  .catch(err =>{
    console.log(err)
    console.error(err)
  })
}

export const logoutUserAction = () => (dispatch) => {
  fetch("/api/auth/logout").then(response =>{
    dispatch({
      type: LOGOUT_USER,
      payload: false
    })
  }).catch(err => {
    console.log(err)
    console.error(err)
  })
}

//Create
export const addCardAction = (payload) => (dispatch) => {
  fetch("/api/cards", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return dispatch({
        type: ADD_CARD,
        payload: data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addStatus = (payload) => (dispatch) => {
  fetch("/api/statuses", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return dispatch({
        type: ADD_STATUS,
        payload: data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

//READ
export const loadCardsAction = () => (dispatch) => {
  fetch("/api/cards")
    .then((response) => {
      return response.json();
    })
    .then((cards) => {
      dispatch({
        type: LOAD_CARD,
        payload: cards,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const loadUsersAction = () => (dispatch) => {
  fetch("/api/users")
    .then((response) => {
      return response.json();
    })
    .then((users) => {
      dispatch({
        type: LOAD_USER,
        payload: users,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const loadStatusesAction = () => (dispatch) => {
  fetch("/api/statuses")
    .then((response) => {
      return response.json();
    })
    .then((statuses) => {
      dispatch({
        type: LOAD_STATUSES,
        payload: statuses,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const loadPriorities = () => (dispatch) => {
  fetch("/api/priorities")
    .then((response) => {
      return response.json();
    })
    .then((priorities) => {
      dispatch({
        type: LOAD_PRIORITIES,
        payload: priorities,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};



//Update

export const editCardAction = (card) => (dispatch) => {
  fetch("/api/cards/edit", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(card),
  })
    .then((response) => {
      return response.json();
    })
    .then((updatedCard) => {
      dispatch({
        type: EDIT_CARD,
        payload: updatedCard,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
//Delete

export const deleteCardAction = (card) => (dispatch) => {
  fetch("/api/cards",{
    method: "DELETE",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(card),
  }).then(res =>{
    return res.json();
  }).then(data =>{
    dispatch({
      type:DELETE_CARD,
      payload: card.id,
    })
  }).catch(err =>{
    console.log(err)
    console.error(err)
  })
}