import {
  ADD_CARD,
  ADD_STATUS,
  LOAD_CARD,
  LOAD_STATUSES,
  LOAD_USER,
  LOAD_PRIORITIES,
  DELETE_CARD,
  EDIT_CARD,
  LOGIN_USER,
  LOGOUT_USER,
  LOGGED_IN_STATUS
} from "../actions";

let initialState = {
  loading: true,
  loggedIn: false,
  userId: null,
  email: null,
  cards: [],
  users: [],
  statuses: [],
  priorities: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {...state, loggedIn: true, userId: action.payload.id, email: action.payload.email}
    case LOGGED_IN_STATUS:
      return {...state,loggedIn: action.payload.loggedIn, loading:false}
      case LOGOUT_USER:
        return {...state,loggedIn: action.payload}
    case ADD_CARD:
      return { ...state, cards: [...state.cards, action.payload] };
    case ADD_STATUS:
      return { ...state, statuses: [...state.statuses, action.payload] };
    case LOAD_CARD:
      return { ...state, cards: action.payload };
    case LOAD_USER:
      return { ...state, users: action.payload };
    case LOAD_PRIORITIES:
      return { ...state, priorities: action.payload };
    case LOAD_STATUSES:
      return { ...state, statuses: action.payload };
      case EDIT_CARD:
        return { ...state, cards: state.cards.map(card =>{
         return card.id === action.payload.id ? action.payload : card
        }) };
   case DELETE_CARD:
     return {...state, cards: state.cards.filter(card => action.payload !== card.id)}
    default:
      return state;
  }
};

export default reducer;
