import React,{useEffect} from "react";
import {useDispatch,useSelector} from "react-redux";
import {loadCardsAction, loadStatusesAction} from "../../actions"
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Column from '../Column'



const Board = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadStatusesAction())
    dispatch(loadCardsAction());
  },[dispatch])

  const statuses = useSelector(state => state.statuses)
  

  return (
    <DndProvider backend={HTML5Backend}>
    <div className="container mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
      {statuses.sort((colA,colB)=>{
        return colA.rank - colB.rank
      }).map((status) =>{
        return <Column key={status.id} status={status}/>  
      })}
    </div>
      </DndProvider>
  )
}

export default Board