import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory} from "react-router-dom";
import {loginAction} from '../../../actions'

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  
  return (
    <div className="flex justify-center w-full mt-32 ">
      <form className="max-w-xs bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">Email</label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            />
            </div>

            <div className="mb-6">
          <label className="black text-gray-700 text-sm font-bold mb-2" htmlFor="password">Password</label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            type="password"
            name="password"
            id="password"
            placeholder="Enter your password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            />
            </div>

            <div className="flex items-center justify-between">
        <button
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          onClick={(e) => {
            if(!email || !password) {
              alert("Please enter a valid email and password.")
            }else{
              e.preventDefault();
              dispatch(loginAction(email, password, history));
            }
          }}
          >
          Login
        </button>
      <Link className="inline-block align-baseline font-bold text-sm text-green-500 hover:text-green-700" to="/register">Sign Up</Link>
          </div>
      </form>
    </div>
  );
};

export default Login;
