import React, { useState } from "react";
import { editCardAction, loadUsersAction, deleteCardAction} from "../../../actions";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-modal";


Modal.setAppElement("#root");

const EditCardModal = ({ ...props }) => {
  const [data, setData] = useState({
    title: "",
    priority: {},
    priority_id: undefined,
    body: "",
    created_by: {},
    assigned_to: {},
    assigned_to_id: undefined
  });
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const handleRequestClose = () =>{
    props.setOpenModal(!props.openModal)
    setData({
      title: "",
      priority: {},
      priority_id: undefined,
      body: "",
      created_by: {},
      assigned_to: {},
      assigned_to_id: undefined
    })
  }
  const handleAfterOpen = () =>{
    setData({...props.card,priority: props.card.priority.id})
    dispatch(loadUsersAction())
  }

  const handleSubmit = () =>{
    dispatch(editCardAction(data));
    props.setOpenModal(!props.openModal)
  }

  return (
    <Modal style={{
      content:{
        maxWidth: "600px",
        left: "50%",
        transform: "translate(-50%, 0)",
        width: '90%',
        // margin: "auto",
        padding: '.5rem'}}} isOpen={props.openModal}
    onAfterOpen={handleAfterOpen}
     onRequestClose={handleRequestClose}>

        <div className="flex flex-col items-center">
      <form className="max-w-lg w-full bg-white shadow-md rounded px-4 pt-6 pb-8 mb-4">
      <button className="bg-green-800 hover:bg-slate-300 hover:text-green-500 font-bold py-1 px-4 text-white focus:outline-none focus:shadow-outline rounded mx-2 my-2" type='button' onClick={handleRequestClose}>Close</button>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Title:</label>
        <input
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        type="text"
        value={data.title}
        onChange={(e) => {
          setData({ ...data, title: e.target.value });
        }}
        />
        </div>

        <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Body:</label>
        <textarea
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        name="body"
        cols="7"
        rows="4"
        value={data.body}
        onChange={(e) => {
          setData({ ...data, body: e.target.value });
        }}
        ></textarea>
        </div>

        <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Priority:</label>
        <select className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" onChange={(e) => {
          setData({ ...data, priority_id: e.target.value });
        }}value={data.priority_id} name="priority">
        <option value={1}>
                  Low
                </option>
                <option value={2}>
                  Medium
                </option>
                <option value={3}>
                  High
                </option>
                <option value={4}>
                  Blocker
                </option>
        </select>
          </div>

        <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Assigned To:</label>
        <select
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        value={data.assigned_to_id}
        name="assigned_to"
        onChange={(e) => {
          setData({ ...data, assigned_to_id: e.target.value });
        }}
        >
          {users.map((user) => {
            return (
              <option key={user.id} value={user.id}>
                {user.last_name}, {user.first_name}
              </option>
            );
          })}
        </select>
          </div>

        <div>
        <button className="bg-green-800 hover:bg-slate-300 hover:text-green-500 font-bold py-1 px-4 text-white focus:outline-none focus:shadow-outline rounded mx-2 my-2" type='submit'
          onClick={handleSubmit}
          >
          Edit
        </button>

        <button className="bg-green-800 hover:bg-slate-300 hover:text-green-500 font-bold py-1 px-4 text-white focus:outline-none focus:shadow-outline rounded mx-2 my-2" type='button' onClick={(e)=>{
          e.preventDefault();
          dispatch(deleteCardAction(data));
          handleRequestClose()
        }}>Delete</button>
        </div>
      </form>
        </div>
    </Modal>
  );
};

export default EditCardModal;
