import React,{useState} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import {logoutUserAction} from '../../actions'
import AddCard from '../Card/AddCard'

const Header = () =>{
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const loggedIn = useSelector(state => state.loggedIn)
    return (
        
            <div className="bg-green-400 p-8 flex justify-between items-center">
            <h2 className="text-lg font-bold text-white">Kanban</h2>

        {loggedIn ? (
            <div>
            <button className="bg-slate-800 hover:bg-slate-200 hover:text-green-500 font-bold py-1 px-4 text-white focus:outline-none focus:shadow-outline rounded mx-2" onClick={()=>{
                setOpenModal(!openModal)
            }}>New Task</button>
            <button className="bg-slate-800 hover:bg-slate-200 hover:text-green-500 font-bold py-1 px-4 text-white focus:outline-none focus:shadow-outline rounded mx-2" onClick={()=>{
                dispatch(logoutUserAction())
            }}>Logout</button>
            <AddCard openModal={openModal} setOpenModal={setOpenModal}/>
            </div>
            )
            : null
        }
        </div>
        
    )
}

export default Header