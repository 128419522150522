import React,{useEffect} from "react";
import {useSelector,useDispatch} from 'react-redux';
import { Route, Redirect } from "react-router-dom";
import {loggedInStatusAction} from "../../actions"

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.loggedIn)
  const loading = useSelector(state => state.loading)
  
  useEffect(() =>{
    dispatch(loggedInStatusAction())
  },[dispatch])

  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth) {
          return <Component {...props} />;
        } else if(loading){
          return <div className="text-center font-bold">Loading...</div>
        }else{
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
      }}
    />
  );
};
